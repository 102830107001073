/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
// import { Link } from "gatsby";
import { getpageLang } from "../shared/extFunctions";
import jsonData from "../shared/json/aboutUs.json";
import Person from "./person";

/* -------------------------------  Import pages ---------------------------- */

/* -------------------------------  Local Images ---------------------------- */

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Team({data}) {
    
    const lang = getpageLang(data.lang);
    const content = lang === "en" ? jsonData.en.ourTeam : jsonData.fr.ourTeam;
    return (
    <>
        <TeamContainer id="team">
            <div className="subContainer">
                <Title>{content.title}</Title>
                <TeamGrid>    
                    <Person data={{style:"hor", class:""}} />
                    <Person data={{style:"ver", class:""}} />
                    <Person data={{style:"hor", class:""}} />
                    <Person data={{style:"ver", class:""}} />
                    <Person data={{style:"hor", class:""}} />
                    <Person data={{style:"ver", class:""}} />
                    <div className="empty"></div>
                    <Person data={{style:"wa7do", class:"last"}} />
                </TeamGrid>
            </div>

        </TeamContainer>
        
    </>
    );
}
export default Team;
/* ---------------------------- Styled Components --------------------------- */
const TeamContainer = styled.section`
    position: relative;
    width: 100vw;
    height:200vh;
    display: flex; 
    justify-content: center;
    padding-top: 10vh;
    .subContainer{
        position: relative;
        width:90vw;
        height: 95%;
        background-color: #fff;
        &::after, &::before{
            content:"";
            display: inline-block;
            width: 50px;
            height: 50px;
            background-color: #000;
            position: absolute;
            z-index:-1;
            border-radius: 2px;
        }
        &::after{            
            top:-4px;
            right:-4px;
        }
        &::before{            
            left:-4px;
            bottom:-4px;
        }
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        /* height:calc((80vw * 9 / 16 + 10vh)*8.5); */
        height: fit-content;
        .subContainer{
            margin-bottom: 10vh;
        }
    }

`;
const Title= styled.h2`
    font-size:clamp(40px, 5.5VW,100px);
    font-family: "Gothic A1", sans-serif;
    position: relative;
    margin: 0;
    top: 0; 
    left: 0;
    z-index: 10;
    width: 4em;
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        /* &::before{
            content:"";
            position: absolute; 
            left:-5vw;
            bottom: 0;
            background-color: #F5F2EE;
            width:100vw;
            height:.7em;
            z-index: -1;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            
        } */
        position: relative;
        margin: 0;
    }
`;
const TeamGrid= styled.div` 
    width: 100%;
    height: 80%;
    /* margin-top: 15%; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        grid-template-columns: 1fr;
        /* margin-top: 2vh; */
        height: calc((80vw * 9 / 16 + 10vh)*7);
        height: fit-content;
        /* gap:5vh; */
    }
`; 

/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */