/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
// import { Link } from "gatsby";
import { getpageLang } from "../shared/extFunctions";
import jsonData from "../shared/json/aboutUs.json";

/* -------------------------------  Import pages ---------------------------- */

/* -------------------------------  Local Images ---------------------------- */
import team1 from "../images/aboutUsPage/low/team1.jpg";
import team2 from "../images/aboutUsPage/low/team2.jpg";
import team3 from "../images/aboutUsPage/low/team3.jpg";
/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Person({data}) {
    const imgURL= data.style === "hor"? team2: data.style === "ver"? team1 : team3;
    return (
    <PersonContainer className={"singlePerson "+data.class}>  
        <div className="packageContainer">
            <img src={imgURL} alt="" className="personImg"/>  
            <h4 className="name" >John Doe</h4>
            <p className="job">Taffar</p>
        </div>    
    </PersonContainer>
    );
}
export default Person;
/* ---------------------------- Styled Components --------------------------- */
const PersonContainer= styled.article`
    height:  50vh;
    
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    text-align : left;
    font-family: "Gothic A1", sans-serif;
    text-transform: uppercase;
    .packageContainer{
        width: fit-content;
    }
    .name{
        font-size: clamp(12px,3vw,25px);
        margin: 0 0 .2em 0;
        padding-right: 1em;
        border-bottom: 2px solid #e1d8cc;
        width: fit-content;
    }
    .job{
        margin:0; 
        width: fit-content;
        background-color: #F5F2EE;
    }
    img{
        max-width: 30vw;        
    }
    &:nth-child(odd){
        img{            
            height: 35vh;
            object-fit: cover;
            aspect-ratio: 4/3;
            width: auto ;
        }
    }
    &:nth-child(even){
        img{   
            height: 40vh;
            object-fit: cover;
            aspect-ratio: 3/4;
            width: auto ;
        }
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        &:nth-child(even),  &:nth-child(odd){

            height: calc(80vw * 9 / 16 + 10vh);
            img{
                max-width:none;
                width: 80vw;
                height: auto;
                /* max-height: 200px; */
                aspect-ratio: 16/9;
    
            }
        }
    }
`;
/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */