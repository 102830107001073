/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
import { getpageLang } from "../shared/extFunctions";
// import { Link } from "gatsby";
import "lazysizes";
import jsonData from "../shared/json/aboutUs.json";

/* -------------------------------  Import pages ---------------------------- */

/* -------------------------------  Local Images ---------------------------- */
import au2 from "../images/aboutUsPage/low/au2.jpg";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function About({data}) {
    const lang = getpageLang(data.lang);
    const content = lang === "en" ? jsonData.en.aboutUs : jsonData.fr.aboutUs;
    return (
    <>
        <AboutContainer id="about">
            <Title>{content.title}</Title>
            <TextContainer>
                <DescU>{content.descA}</DescU>
                <DescD>{content.descB}</DescD>
            </TextContainer>
            <ImgContainer>
                <img className="lazyload" data-src={content.image}  src={content.imageblur} alt="alt" />
            </ImgContainer>
            <HrecFill/>
        </AboutContainer>
    </>
    );
}
export default About;
/* ---------------------------- Styled Components --------------------------- */
const AboutContainer = styled.section`
    position: relative;
    font-family: "Roboto Condensed", sans-serif;
    /* font-size: clamp(20px,4vw,40px); */
    font-size: clamp(15px,2.7vw, 25px);
    width:100vw;
    height: 100vh;
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        height: 110vh;
    }
`;
const Title= styled.h2`
    font-size:clamp(40px, 5.5VW,100px);
    font-family: "Gothic A1", sans-serif;
    position: absolute;
    margin: 0;
    top: 15vh; 
    left: 5vw;
    z-index: 10;
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        &::before{
            content:"";
            position: absolute; 
            left:-5vw;
            bottom: 0;
            background-color: #e1d8cc;
            width:100vw;
            height:.7em;
            z-index: -1;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            
        }
    }
`;
const TextContainer= styled.div`
    width: fit-content;
    height:fit-content;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    position: absolute;
    top: 30vh;
    left: 5vw;
    & > p{        
        margin:0;
        width: 60vw;
        height: 25vh;
        line-height:1em;
        background-color:  #F5F2EE;
        padding: 0 2vw;
        display: flex;
        align-items: center;
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        height: 70vh;
        top: 35vh;
        left: 0;
        width: 100vw;
        box-shadow: none;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &> p{
            width: 100vw;
            height: 15vh;
            text-align: center;
            padding: 0;
        }
    }
`;
const DescU= styled.p`
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
    }
`;
const DescD= styled.p`
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
    }
`;
const ImgContainer= styled.div`
    position: absolute;
    top:30vh;
    left: 70vw;
    img{
        height: 50vh;
        aspect-ratio: 1/1;
        object-fit: cover;
        width: 25vw;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        top: 50vh;
        left: 0;
        width: 100vw;
        height: 40vh;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 35vh;
            max-width: none;
            width: auto;
        }
    }
`;
const HrecFill= styled.div`
    position: absolute;
    left: 0;
    bottom: 2vh;
    background-color:#e1d8cc;

    width:60vw;
    height: 5vh;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        height: 2vh;
    }
`;
/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */