/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* ---------------------------------  Packages ------------------------------ */
import React, {useRef, useEffect, useState} from "react";
// import Helmet from "react-helmet";
import styled from "styled-components";


/* -------------------------  Import pages/ components ---------------------- */
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import Cover from "../aboutUsComponents/cover.js";
import About from "../aboutUsComponents/about.js";
import FloatingSocials from "../LandingPageComponents/floatingSocials.js";
import { SEO } from "../components/seo.jsx";
import "./style.scss";
/* -------------------------------  Local Images ---------------------------- */
import minLogo from "../shared/A.png";
import Mission from "../aboutUsComponents/mission.js";
import Team from "../aboutUsComponents/team.js";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function AboutUs({location, data}) {  
  const params = new URLSearchParams(location.search);
  const lang= params.get("lang");

    return (
      <>
        <Header data={{lang: lang}} />
        
        <Cover data={{lang: lang}}/>
        <About data= {{lang:lang}}/>
        <Mission data= {{lang:lang}}/>
        {/* <Team data = {{ lang:lang}}/> */}
        <FloatingSocials data={{lang:lang}} />

        <Footer data={{lang: lang}} />
      </>
  );
}
export default AboutUs;

export const Head = () => (
  <>
      <link rel="icon" type="image/x-icon" href={minLogo}/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <SEO title="About - ATDCE"/>

  </>
);
/* ---------------------------- Styled Components --------------------------- */
const Filler= styled.div`
  width: 1px;
  height: 250vh;
`;


/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */