/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, {useRef, useEffect, useState} from "react";
// import * as React from "react";
/* ---------------------------------  Packages ------------------------------ */
// import PropTypes from "prop-types";
// import Helmet from "react-helmet";
import styled from "styled-components";
// import { Link } from "gatsby";
import  {getpageLang} from "../shared/extFunctions";

/* -------------------------------  Import pages ---------------------------- */
import ScrollArrow from "../components/scrollArrow";
/* -------------------------------  Local Images ---------------------------- */
import au1 from "../images/aboutUsPage/low/au1.jpg";

/* -------------------------------------------------------------------------- */
/*                                Main Content                                */
/* -------------------------------------------------------------------------- */

function Cover({data}) {
    const lang = getpageLang(data.lang);
    const myRef = useRef();
    useEffect(() => {
        // myRef.current.innerHTML = "ABOUT US \n" + myRef.current.classList[0];
    }, []);
    return (
        <>
            <CoverContainer>
                {/* <h1 ref={myRef} className="color-dodge">
                    ABOUT US 
                </h1> */}
                <ScrollArrow data={{direction:"down", link:"?lang="+lang+"#about"}} />
            </CoverContainer>
        </>
    );
}
export default Cover;
/* ---------------------------- Styled Components --------------------------- */

const CoverContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background-image: url(${au1});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    filter: brightness(0.7);
    @media screen and ((max-device-width: 700px) or (max-width: 700px)) {
        background-position: 35%;
    }


    /* filter: brightness(0.7); */

    h1 {
        font-family: "Gothic A1", sans-serif;
        font-size: 10vw;

        width: 100vw;
        text-align: center;
        /* backdrop-filter: blur(2px); */
        /* -webkit-text-stroke: 2px #8788B8; */
        position: absolute;
        top: 20vh;
        &.multiply {
            color: #e1d8cc; //#34354d ;
            mix-blend-mode: multiply;
        }
        &.difference {
            color: #34354d;
            mix-blend-mode: difference;
        }
        &.screen {
            color: #e1d8cc80; //#34354d ;
            mix-blend-mode: screen;
        }
        &.lighten {
            color: #ffdfb880; //#34354d ;
            mix-blend-mode: lighten;
        }
        &.overlay {
            color: #584a37; //#34354d ;
            mix-blend-mode: overlay;
        }
        &.exclusion {
            color: #2b4256; //#34354d ;
            mix-blend-mode: exclusion;
        }
        &.saturation {
            color: #fff; //#34354d ;
            mix-blend-mode: saturation;
        }
        &.luminosity {
            color: #bb6d00; //#34354d ;
            mix-blend-mode: luminosity;
        }
        &.color-dodge {
            color: #5a4b38; //#34354d ;
            mix-blend-mode: color-dodge;
        }
        &.color-burn {
            color: #e1d8cc; //#34354d ;
            mix-blend-mode: color-burn;
        }
        &.hue {
            color: #e1d8cc; //#34354d ;
            mix-blend-mode: hue;
        }
    }
`;
/* -------------------------------------------------------------------------- */
/*                             External Functions                             */
/* -------------------------------------------------------------------------- */
